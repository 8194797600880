import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";
import { objectToQuerystring, slugify } from '../../utils/helpers'
const recaptchaRef = React.createRef();
import * as FormFields from './Fields';
import { FaCircleNotch, FaExclamation } from 'react-icons/fa';
import ls from 'local-storage'
import LazyLoad from 'react-lazy-load';

class Index extends Component {
    constructor(props) {
      const { thankYou = null, data, id = null  } = props
      const { siteSettings, allWordpressWpForm } = data
      var settings = id && allWordpressWpForm && allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id) )
      settings = settings && settings[0] && settings[0].formJson && settings[0].formJson

      super(props);
      this.state = {
        error: null,
        loading: false,
        submitted: false,
        ready: false,
        id: id,
        thankYou: thankYou,
        siteKey: siteSettings.options.googleSiteKey,
        settings: settings && settings.formFields && settings.formFields,
        lead: {
          location: typeof window !== `undefined` ? window.location.href : 'Server',
          recipientAddress: settings && settings.recipientAddress ? settings.recipientAddress : null,
          formId: id
        },
      }
    }

    handleSubmit = ( e ) => {
      e.preventDefault()
      const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue() ;
      const formReValue = e.target.elements['g-recaptcha-response'].value;

      if ( formReValue !== '' && recaptchaValue === formReValue ) {
        this.captchaSubmit( e )
      }
    }

    checkCaptcha = () => {
      const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue() ;
      if ( recaptchaValue ) {
        this.setState({ready: true})
      }else{
        this.setState({ready: false})
      }
    }

    captchaSubmit = async ( e ) => {
      this.setState({ loading: true });
      //const theFunctionsUrl = `/.netlify/functions/leads`
      const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
      const appSecret = process.env.GATSBY_WEB_APP_SECRET
      //Add recipient
      const { lead } = this.state
      fetch(leadFunctionUrl, {
        method: 'POST',
        body: JSON.stringify({secret: appSecret, lead: lead})
      })
      .then(response => {
       if (response.status === 200) {
         this.setState({ loading: false, submitted: true, lead: {} });
         this.props.thankYou && this.props.thankYou !== '' && navigate(this.props.thankYou)
       } else {
         this.setState({ loading: false, error: true, lead: {} });
       }

      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, lead: {} });
      });
    }

    //Form change
    handleChange(e) {
       let lead = this.state.lead
       //If file
       if (e.target.type === 'file') {

         let fileName = `lead-file-${e.target.name}`
         let file = e.target.files[0];
         lead[fileName] = { data: '', file: '' }

         if (file) {
            const reader = new FileReader(file);
            reader.readAsDataURL(file);
            reader.onload = () => {
              // set image and base64'd image data in component state
              lead[fileName].data = reader.result
              lead[fileName].file = file.name
            }
          }
       } else if ( e.target.type === 'checkbox' ) {
         //Toggle value on off
         lead[e.target.name] === e.target.value ? lead[e.target.name] = '' : lead[e.target.name] = e.target.value
       } else {
         //All other fields
         lead[e.target.name] = e.target.value
       }
       this.setState({lead})
    }

    render() {
      const { loading, submitted, error, lead, siteKey, id, settings } = this.state
      if (!id) return 'No form id!'

      if (!settings) return 'There is a problem with the JSON!';

      if ( submitted ) return <div className="lead-form thank-you"><h3>Thank you!</h3><p>Your message has been sent.</p></div>

      const formatComponentName = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      return (
        <div className="lead-form">
          {error && <div className="error"><div>There was a error <FaExclamation /></div></div>}
          {loading && <div className="loading"><div><FaCircleNotch className="spin" /> Loading...</div></div>}
          <form onSubmit={event => {this.handleSubmit(event)}}>
            <div className="form-inner">
              {settings.map((field, index) => {
                const FormComponent = FormFields[formatComponentName(field.type)];
                //Add quote to lead
                if (field.type === 'quoteList') {
                  this.state.lead['quote'] = ls.get('quote')
                }
                return (
                  <FormComponent key={index} field={field} value={lead} handleChange={e => this.handleChange(e)} />
                )
              })}
              <div className="captcha-wrapper">
                <button onMouseOver={e => this.checkCaptcha()} className={`submit${this.state.ready ? ' ready' : ' not-ready'}`}>Submit</button>
                <LazyLoad className="captcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                />
                </LazyLoad>
              </div>
            </div>
          </form>
        </div>
      )
    }

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        },
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                }
                name
                className
                accept
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
